// ProjectDataForm.js
import React from 'react';

const ProjectDataForm = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-6 text-center">Inisiasi Project Data</h2>
      <form>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Logo URL:
          </label>
          <input
            type="text"
            name="logoUrl"
            value={formData.logoUrl}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Project Name:
          </label>
          <input
            type="text"
            name="projectName"
            value={formData.projectName}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Project Logo (Upload):
          </label>
          <input
            type="file"
            name="projectLogo"
            onChange={(e) => setFormData({ ...formData, projectLogo: e.target.files[0] })}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Token Name:
          </label>
          <input
            type="text"
            name="tokenName"
            value={formData.tokenName}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Token Symbol:
          </label>
          <input
            type="text"
            name="tokenSymbol"
            value={formData.tokenSymbol}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Project Description:
          </label>
          <textarea
            name="projectDescription"
            value={formData.projectDescription}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
            rows="4"
          />
        </div>

        {/* Social Media Links */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Twitter URL:
          </label>
          <input
            type="url"
            name="twitterUrl"
            value={formData.twitterUrl}
            onChange={handleInputChange}
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Telegram URL:
          </label>
          <input
            type="url"
            name="telegramUrl"
            value={formData.telegramUrl}
            onChange={handleInputChange}
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Discord URL:
          </label>
          <input
            type="url"
            name="discordUrl"
            value={formData.discordUrl}
            onChange={handleInputChange}
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="flex justify-between">
          <button
            type="button"
            onClick={prevStep}
            className="py-2 px-4 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200"
          >
            Back
          </button>
          <button
            type="button"
            onClick={nextStep}
            className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProjectDataForm;
