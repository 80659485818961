// SaleDataForm.js
import React from 'react';

const SaleDataForm = ({ formData, setFormData, nextStep }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-6 text-center">Inisiasi Sale Data</h2>
      <form>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Token Address:
          </label>
          <input
            type="text"
            name="tokenAddress"
            value={formData.tokenAddress}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Funding Token Address:
          </label>
          <input
            type="text"
            name="fundingToken"
            value={formData.fundingToken}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Price Per Token (ETH):
          </label>
          <input
            type="number"
            name="pricePerToken"
            value={formData.pricePerToken}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Max Cap (ETH):
          </label>
          <input
            type="number"
            name="maxCap"
            value={formData.maxCap}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Start Time:
          </label>
          <input
            type="datetime-local"
            name="startTime"
            value={formData.startTime}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            End Time:
          </label>
          <input
            type="datetime-local"
            name="endTime"
            value={formData.endTime}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Vesting Duration (seconds):
          </label>
          <input
            type="number"
            name="vestingDuration"
            value={formData.vestingDuration}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Cliff Duration (seconds):
          </label>
          <input
            type="number"
            name="cliffDuration"
            value={formData.cliffDuration}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            TGE Time:
          </label>
          <input
            type="datetime-local"
            name="tgeTime"
            value={formData.tgeTime}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            TGE Percentage:
          </label>
          <input
            type="number"
            name="tgePercentage"
            value={formData.tgePercentage}
            onChange={handleInputChange}
            required
            className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <button
          type="button"
          onClick={nextStep}
          className="w-full mt-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
        >
          Next
        </button>
      </form>
    </div>
  );
};

export default SaleDataForm;
