// ReviewForm.js
import React from 'react';

const ReviewForm = ({ formData, prevStep, submitForm }) => {
  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-6 text-center">Review Data</h2>
      <ul className="mb-6">
        <li className="mb-2"><strong>Token Address:</strong> {formData.tokenAddress}</li>
        <li className="mb-2"><strong>Price Per Token:</strong> {formData.pricePerToken} ETH</li>
        <li className="mb-2"><strong>Max Cap:</strong> {formData.maxCap} ETH</li>
        <li className="mb-2"><strong>Start Time:</strong> {formData.startTime}</li>
        <li className="mb-2"><strong>End Time:</strong> {formData.endTime}</li>
        <li className="mb-2"><strong>Vesting Duration:</strong> {formData.vestingDuration} seconds</li>
        <li className="mb-2"><strong>Cliff Duration:</strong> {formData.cliffDuration} seconds</li>
        <li className="mb-2"><strong>TGE Time:</strong> {formData.tgeTime}</li>
        <li className="mb-2"><strong>TGE Percentage:</strong> {formData.tgePercentage}%</li>
        <li className="mb-2"><strong>Logo URL:</strong> {formData.logoUrl}</li>
        <li className="mb-2"><strong>Project Name:</strong> {formData.projectName}</li>
        <li className="mb-2"><strong>Token Name:</strong> {formData.tokenName}</li>
        <li className="mb-2"><strong>Token Symbol:</strong> {formData.tokenSymbol}</li>
        <li className="mb-2"><strong>Project Description:</strong> {formData.projectDescription}</li>
        <li className="mb-2"><strong>Twitter URL:</strong> {formData.twitterUrl}</li>
        <li className="mb-2"><strong>Telegram URL:</strong> {formData.telegramUrl}</li>
        <li className="mb-2"><strong>Discord URL:</strong> {formData.discordUrl}</li>
      </ul>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={prevStep}
          className="py-2 px-4 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200"
        >
          Back
        </button>
        <button
          type="button"
          onClick={submitForm}
          className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ReviewForm;
