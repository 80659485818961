// import { BSC } from '@usedapp/core'
// import { Base } from '@usedapp/core'
// import { BscTestnet } from './chain'
import { Vanar, CoreChain, BccTest } from './testchain'

 // export const BACKEND_URL = "https://launchpad-backend-green.vercel.app"
export const BACKEND_URL = "https://bullpad-server.vercel.app"
// export const BACKEND_URL = "http://localhost:8080"
// export const BACKEND_URL = "https://backend-chi-eight-11.vercel.app"

export const Ido_Factory = {
  [CoreChain.chainId]: "",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const FairLaunch_FACTORY = {
  [CoreChain.chainId]: "0xB01F7FB08124FFE2DA9f1A34146C9d24c0d84282",
  [Vanar.chainId]: "0xc4fFE53C82028C2Bb775AC3EEC48a10D79143f9C",
  [BccTest.chainId]: "",
}

export const FairLaunchErc_FACTORY = {
  [CoreChain.chainId]: "0x3D6281e5cA11a7746184918bdDFAAA9d351874c1",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const Private_FACTORY = {
  [CoreChain.chainId]: "0xC75BD0d0b9B12dAefB0354C478620484319d451B",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const PrivateErc_FACTORYADRESS = {
  [CoreChain.chainId]: "0x80EC4e2A0983c84F02c0335D24290FEa8dc61a9E",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const PublicVesting = {
  [CoreChain.chainId]: "0x5709985bEb87f96873F908d161DcB949183798E6",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const Public_FACTORY = {
  [CoreChain.chainId]: "0x8f1593267066a069d79F18a6520d519d4530bF04",
  [Vanar.chainId]: "0x88dBb8207f757C2D89eeb62Ea98418794D551e39",
  [BccTest.chainId]: "",
}

export const PublicErc_FACTORYADRESS = {
  [CoreChain.chainId]: "0x7bdF65A7d9a8b6d01F43094b08AF04faf1138739",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const Staking_Factory = {
  [CoreChain.chainId]: "0xC6Ca0604aa13f08478435801010cC8bbBF417aF2",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const LOCK_ADDRESS = {
  [CoreChain.chainId]: "0xEd5c22739B3D6975E597A34Ca8B5540127473bC9",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const ROUTER_ADDRESS = {
  [CoreChain.chainId]: "0x37b1fC2b5062526bf6b177Fdcd5992122b138c4d",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const ADMIN_ADDRESS = {
  [CoreChain.chainId]: "0x34C631C5AfB39B448b2e50378F6D2C42500175F6",
  [Vanar.chainId]: "0x34C631C5AfB39B448b2e50378F6D2C42500175F6",
  [BccTest.chainId]: "",
}

export const USDT_ADDRESS = {
  [CoreChain.chainId]: "0x96a0fa4da6270cf2b65dd21e4fc2e387edeb8951",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const TOWER_TOKEN = {
  [CoreChain.chainId]: "0x8D6E7213bad28E00156c7ecddEFac64Cc508CAD5",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const USDC_ADDRESS = {
  [CoreChain.chainId]: "0xd9ad6a7d0052e04b1eaa7b340d48b0fcae1aff96",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const TWSDROP_ADDRESS = {
  [CoreChain.chainId]: "0x5B40F80d474526632D36Cc4fBb1Fb3920145c9ec",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}
