// index.js
import React, { useState } from 'react';
import BaseLayout from '../../components/BaseLayout/BaseLayout'
import { ethers } from "ethers";
import { useEthers } from "@usedapp/core";
import SaleDataForm from 'components/IDO/Initialsale';
import ProjectDataForm from 'components/IDO/ProjectData';
import ReviewForm from 'components/IDO/Review';
import { Ido_Factory } from "config/constants/LaunchpadAddress";
import IdoAbi from "config/abi/IdoAbi.json";

const IDOForm = () => {
  const [step, setStep] = useState(1);
  const { account, library } = useEthers();
  const [formData, setFormData] = useState({
    tokenAddress: '',
    pricePerToken: '',
    maxCap: '',
    startTime: '',
    endTime: '',
    vestingDuration: '',
    cliffDuration: '',
    tgeTime: '',
    tgePercentage: '',
  });

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const submitForm = async () => {
    try {
      // Pastikan user terhubung ke wallet MetaMask
      if (!account) {
        alert("Please connect your MetaMask wallet.");
        return;
      }

      // Membuat instance dari contract factory
      const IDOFactory = new ethers.Contract(Ido_Factory, IdoAbi, library.getSigner());

      // Menentukan parameter yang akan di-pass ke kontrak
      const args = [
        formData.tokenAddress,
        ethers.utils.parseEther(formData.pricePerToken.toString()), // Price per token
        ethers.utils.parseEther(formData.maxCap.toString()), // Max cap
        Math.floor(new Date(formData.startTime).getTime() / 1000), // Start time (in seconds)
        Math.floor(new Date(formData.endTime).getTime() / 1000), // End time (in seconds)
        formData.vestingDuration, // Vesting duration (in seconds)
        formData.cliffDuration, // Cliff duration (in seconds)
        Math.floor(new Date(formData.tgeTime).getTime() / 1000), // TGE time (in seconds)
        formData.tgePercentage // TGE percentage
      ];

      // Deploy kontrak dengan parameter yang diberikan
      const contractTransaction = await IDOFactory.createIDO(...args);

      // Tunggu sampai transaksi di-mined
      const receipt = await contractTransaction.wait();

      // Ambil alamat kontrak yang dideploy
      const contractAddress = receipt.events[0].args[0]; // Ini tergantung pada event yang dipancarkan oleh fungsi createIDO
      console.log("Contract deployed at:", contractAddress);
      alert(`Contract deployed at: ${contractAddress}`);
    } catch (error) {
      console.error("Error deploying contract:", error);
      alert("Failed to deploy contract");
    }
  };

  return (
    <BaseLayout>
    <div className="ido-form">
      {step === 1 && (
        <SaleDataForm formData={formData} setFormData={setFormData} nextStep={nextStep} />
      )}
      {step === 2 && (
        <ProjectDataForm
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      )}
      {step === 3 && (
        <ReviewForm formData={formData} prevStep={prevStep} submitForm={submitForm} />
      )}
    </div>
    </BaseLayout>
  );
};

export default IDOForm;
